import React, {useEffect, useState} from "react";
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from "react-router-dom";
import "./Ordercentral.css";
import Header from "../../components/Header";
//import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import Orderhantering from "../Orderhantering/Orderhantering";
import OrderForm from "../../components/OrderForm";
import OrderFormReturn from "../../components/OrderFormReturn";
import FormanscykelFonsterHantering from "../FormanscykelFonsterHantering/FormanscykelFonsterHantering";
import BG from "../BG/BG";
import FormanscykelFonsterHanteringPC from "../FormanscykelFonsterHanteringPC/FormanscykelFonsterHanteringPC";
import {fetchOrder} from "../../Redux/actions/orderAction";
import {useDispatch} from "react-redux";
import API from "../../utils/API";

function Ordercentral() {
    const dispatch = useDispatch();
    const [adminAccess, setAdminAccess] = useState(false);
    const [shopStaff, setShopStaff] = useState(false);

    const handleTabActive = (e) => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        void dispatch(fetchOrder(""));

        const tabLinks = document.getElementsByClassName("ordercentral__tabLink");

        for (let tabLink of tabLinks){
            tabLink.classList.remove("ordercentral__tabLinkClicked");
        }

        e.target.classList.add("ordercentral__tabLinkClicked");
    };

    const handleTabActiveLarsson = (e) => {

        const tabLinks = document.getElementsByClassName("ordercentral__larsson__tabLink");

        for (let tabLink of tabLinks){
            tabLink.classList.remove("ordercentral__larsson__tabLinkClicked");
        }

        e.target.classList.add("ordercentral__larsson__tabLinkClicked");
    };

    useEffect(() => {
        const getLoggedInUserRights = async () => {
            await API.getLoggedInUserRights()
                .then((response) => {
                    if (response.data.name === "Butikspersonal")
                        setShopStaff(true);
                    setAdminAccess(response.data.adminaccess);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getLoggedInUserRights();
    }, []);

    return (
        <Router>
            <div className="ordercentralTitle__container">
                <Header title="Ordercentral" />
            </div>
            <div className="ordercentral__container">
                {/*
                <NewsfeedBanner title="AKTUELLA NYHETER" />
                */}
                <div className="ordercentral__tab__container">
                    <Link to="/ordercentral/larsson/order"><button className="ordercentral__tabLink ordercentral__larsson__tabLinkClicked" onClick={(e) => handleTabActive(e)}>ORDERCENTRAL</button></Link>
                    { adminAccess && <Link to="/ordercentral/formanscykel"><button className="ordercentral__tabLink" onClick={(e) => handleTabActive(e)}>FÖRMÅNSFÖNSTER | Fönster</button></Link> }
                    { shopStaff && <Link to="/ordercentral/formanscykel"><button className="ordercentral__tabLink" onClick={(e) => handleTabActive(e)}>FÖRMÅNSFÖNSTER | Fönster</button></Link> }
                    { adminAccess && <Link to="/ordercentral/BG"><button className="ordercentral__tabLink" onClick={(e) => handleTabActive(e)}>FÖRMÅNSFÖNSTER | BG/KAL</button></Link> }
                    { adminAccess && <Link to="/ordercentral/personalcyklar"><button className="ordercentral__tabLink" onClick={(e) => handleTabActive(e)}>FÖRMÅNSFÖNSTER | Översikt</button></Link> }
                    {/* adminAccess && <Link to="/ordercentral/OU"><button className="ordercentral__tabLink" onClick={(e) => handleTabActive(e)}>OU</button></Link> */}
                    {/*
                    <Link to="/ordercentral/webbordrar"><a className="ordercentral__tabLink" onClick={handleTabActive}>WEBBORDRAR</a></Link>
                    <Link to="/ordercentral/avtalskunder"><a className="ordercentral__tabLink" onClick={handleTabActive}>AVTALSKUNDER</a></Link>
                    <Link to="/ordercentral/clickochcollect"><a className="ordercentral__tabLink" onClick={handleTabActive}>CLICK & COLLECT</a></Link>
                    */}
                    {/*
                    <button className="ordercentral__supportButton"><HeadsetMicIcon className="ordercentral__supportIcon" />SUPPORT</button>
                    */}
                </div>
            </div>
            <Switch>
                <Route path="/ordercentral/larsson">
                    <Orderhantering />
                    <div className="ordercentral__orderReturnDiv">
                        <Link to="/ordercentral/larsson/order"><button className="ordercentral__larsson__tabLink ordercentral__larsson__tabLinkClicked" onClick={(e) => handleTabActiveLarsson(e)}>ORDER</button></Link>
                        {/* <Link to="/ordercentral/larsson/return"><a className="ordercentral__larsson__tabLink" onClick={handleTabActiveLarsson}>RETUR</a></Link> */}
                    </div>
                    <Route path="/ordercentral/larsson/order">
                        <div className="ordercentral__larsson__div">
                            <OrderForm />
                        </div>
                    </Route>
                    <Route path="/ordercentral/larsson/return">
                        <div className="ordercentral__larsson__div">
                            <OrderFormReturn />
                        </div>
                    </Route>
                </Route>
                { adminAccess && <Route path="/ordercentral/formanscykel">
                    <div className="ordercentral__larsson__div">
                        <FormanscykelFonsterHantering />
                    </div>
                </Route>}
                { shopStaff && <Route path="/ordercentral/formanscykel">
                    <div className="ordercentral__larsson__div">
                        <FormanscykelFonsterHantering shopstaff={shopStaff} />
                    </div>
                </Route>}
                { adminAccess && <Route path="/ordercentral/BG">
                    <div className="ordercentral__larsson__div">
                        <BG />
                    </div>
                </Route> }
                { adminAccess && <Route path="/ordercentral/personalcyklar">
                    <div className="ordercentral__larsson__div">
                        <FormanscykelFonsterHanteringPC />
                    </div>
                </Route>}
                {/* adminAccess && <Route path="/ordercentral/OU">
                    <div className="ordercentral__larsson__div">
                        <OU />
                    </div>
                </Route> */}
                <Route path="/ordercentral/webbordrar">

                </Route>
            </Switch>
            <Redirect to="/ordercentral/larsson/order" />
        </Router>
    );
}

export default Ordercentral;
